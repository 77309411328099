import React, { useRef, useState } from "react";
import AppBar from "../Components/AppBar";
import { useNavigate } from "react-router-dom";
import Start from "../Components/start";
import Card from "../Components/card";
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Paper from "@mui/material/Paper";
import { Divider, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import SignatureCanvas from "react-signature-canvas";
import SendIcon from "@mui/icons-material/Send";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Swal from "sweetalert2";
import "./home.css";
import KeyboardDoubleArrowUpOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowUpOutlined";
import IconButton from "@mui/material/IconButton";
import { v4 as uuidv4 } from "uuid";
import { CircularProgress } from "@mui/material";

import P1 from '../Images/sincerely-media-p-NQlmGvFC8-unsplash.jpg';
import P2 from '../Images/navi-honMN5SyAnM-unsplash.jpg';
import P3 from '../Images/navi-XUUBZRej_T0-unsplash.jpg';


// Customizing the theme
//new
const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            borderColor: "#93AB4F",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "#93AB4F",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "#93AB4F",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#93AB4F",
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#93AB4F",
          "&.Mui-checked": {
            color: "#93AB4F",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#93AB4F",
          "&.Mui-checked": {
            color: "#93AB4F",
          },
        },
      },
    },
  },
});

const provinceOptions = {
  // Gauteng: [
  //   //"Vaal(Sebokeng)", "West Rand(kagiso)", //,
  // ],
  // Limpopo: ["Thohoyandou"] , "Nkomazi"
  // Mpumalanga: ["Ermelo"],
  // "North West": [ "Zeerust"], //  ,"Rustenburg"""Taung,
  // "Northern Cape": ["Springbok", "Upington"],
  //"Free State": ["Bethlehem"],//,"Qwaqwa"
  // KZN: ["Pietermaritzburg", "Durban"],
  // "Eastern Cape": ["East London"], //"Mthatha" ,  "Bizana"
     "Western Cape": ["Ottery"],//"Mossel Bay"
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
function getDate() {
  const today = new Date();
  const options = {
    timeZone: "Africa/Johannesburg",
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }

  const formatter = new Intl.DateTimeFormat("en-US", options);
  const dateParts = formatter.formatToParts(today);

  const day = dateParts.find((part) => part.type === "day").value;
  const monthName = dateParts.find((part) => part.type === "month").value;
  const year = dateParts.find((part) => part.type === "year").value;
  const hours = dateParts.find((part) => part.type === "hour").value;
  const minutes = dateParts.find((part) => part.type === "minute").value;

  return `${day} ${monthName} ${year} ${hours}:${minutes}`;
}

console.log(getDate());

let nextId = 1;

function generateNextId() {
  const idString = String(nextId).padStart(3, "0");
  nextId += 1;
  return idString;
}

function Home() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const mainFormRef = useRef(null);
  const signCanvasRef = useRef(null);
  const [registrationType, setRegistrationType] = useState("Pre-Registration");
  const [ticket, setTicket] = useState("");

  const handleRegistrationTypeChange = (e) => {
    const selectedType = e.target.value;
    setRegistrationType(selectedType);

    if (selectedType === "On-site Registration") {
      // const uniqueId = generateNextId();
      const uniqueId = uuidv4().substring(0, 3);
      setTicket(uniqueId);
      console.log("Generated Ticket ID:", uniqueId); // Debugging log
    } else {
      setTicket("");
    }
  };

  // const handleRegistrationTypeChange = (e) => {
  //   const selectedType = e.target.value;
  //   setRegistrationType(selectedType);
  // };

  const [formData, setFormData] = useState({
    timestamp: getDate(),
    disclaimer: "",
    province: "Western Cape",
    eventType: "Retirement Member Campaign(RMC)",
    area: "Ottery",
    title: "",
    idNumber: "",
    pensionNumber: "",
    department: "",
    memberType: "",
    phoneNumber: "",
    email: "",
    consentID: "",
    phone: "",
    physicalAddress: "",
    helpWith: [],
    otherText: "",
    campaignSource: "",
    initials: "",
    firstName: "",
    lastName: "",
    ticket: "",
    signatureUrl: "",
    attendace: "TRUE",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const updatedHelpWith = checked
      ? [...formData.helpWith, value]
      : formData.helpWith.filter((item) => item !== value);
    setFormData({ ...formData, helpWith: updatedHelpWith });
  };

  const handleProvinceChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, province: value, area: "" }); // Reset area when province changes
  };

  const handleSubmit = async () => {
    const isValid = validateForm();

    if (isValid) {
      if (signCanvasRef.current) {
        const signatureUrl = signCanvasRef.current
          .getTrimmedCanvas()
          .toDataURL();
        console.log("Signature URL:", signatureUrl);

        const completeFormData = {
          ...formData,
          signatureUrl,
          registrationType,
          ticket,
        };
        console.log("Complete Form Data:", completeFormData); // Debugging log

        const finalFormData = new FormData();
        finalFormData.append("Timestamp", formData.timestamp);
        Object.entries(completeFormData).forEach(([key, value]) => {
          finalFormData.append(key, value);
        });

        setLoading(true);
        try {
          const response = await fetch(
            "https://script.google.com/macros/s/AKfycbzufoNznCNd5JBquJtjNaKTsEjUEcJOzctvMQ25Svg02n3Y4eB-MfALfFoMqvGyv5nEUA/exec",
            {
              method: "POST",
              body: finalFormData,
            }
          );

          if (response.ok) {
            const responseData = await response.json();
            console.log("Form submitted successfully:", responseData);
            Swal.fire({
              title: "Success",
              text: "Form submitted successfully!",
              icon: "success",
              customClass: {
                confirmButton: "swal-confirm-button",
              },
              buttonsStyling: false,
              confirmButtonColor: "#4CAF50",
            });
            navigate("/ticket", {
              state: {
                timestamp: formData.timestamp,
                firstName: formData.firstName,
                lastName: formData.lastName,
                eventType: formData.eventType,
                area: formData.area,
                helpWith: formData.helpWith,
                otherText: formData.otherText,
                ticket: responseData.data.ticket,
              },
            });

            setFormData({
              timestamp: "",
              disclaimer: "",
              province: "",
              eventType: "",
              area: "",
              title: "",
              idNumber: "",
              pensionNumber: "",
              department: "",
              memberType: "",
              phoneNumber: "",
              email: "",
              consentID: "",
              phone: "",
              physicalAddress: "",
              helpWith: [],
              campaignSource: "",
              initials: "",
              firstName: "",
              lastName: "",
              ticket: "",
              signatureUrl: "",
            });

            if (registrationType === "On-site Registration") {
              setTicket("");
            }

            setRegistrationType("");
          } else {
            console.error("Failed to submit form:", response.statusText);
            Swal.fire("Error", "Failed to submit form", "error");
          }
        } catch (error) {
          console.error("An error occurred while submitting the form:", error);
          // Swal.fire("Error", "Connection timeout...Please try again", "error");
          Swal.fire({
            title: "Error",
            text: "Connection timeout...Please try again",
            icon: "error",
            customClass: {
              confirmButton: "swal-confirm-button",
            },
            buttonsStyling: false,
            confirmButtonColor: "#4CAF50",
          });
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const validateForm = () => {
    const {
      disclaimer,
      province,
      eventType,
      area,
      title,
      idNumber,
      department,
      memberType,
      email,
      consentID,
      phone,
      physicalAddress,
      helpWith,
      campaignSource,
      registrationType,
      initials,
      firstName,
      lastName,
    } = formData;

    if (!disclaimer) {
      Swal.fire({
        title: "Error",
        text: "Please provide a disclaimer",
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    }

    if (!province) {
      Swal.fire({
        title: "Error",
        text: "Please select a province",
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    }

    if (!eventType) {
      Swal.fire({
        title: "Error",
        text: "Please provide an event type",
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    }

    if (!area) {
      Swal.fire({
        title: "Error",
        text: "Please provide an area",
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    }

    if (!title) {
      Swal.fire({
        title: "Error",
        text: "Please provide a title",
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    }

    if (!initials) {
      Swal.fire({
        title: "Error",
        text: "Please provide initials",
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    }

    if (!firstName) {
      Swal.fire({
        title: "Error",
        text: "Please provide a first name",
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    }

    if (!lastName) {
      Swal.fire({
        title: "Error",
        text: "Please provide a last name",
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    }

    if (!consentID) {
      Swal.fire({
        title: "Error",
        text: "Please select consent option",
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    } else if (consentID === "No") {
      Swal.fire({
        title: "Error",
        text: "Please provide consent in order to process your information",
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    }

    if (!/^\d{13}$/.test(idNumber)) {
      Swal.fire({
        title: "Error",
        text: "ID number must be 13 digits",
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    }

    if (!department) {
      Swal.fire({
        title: "Error",
        text: "Please provide a department",
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    }

    if (!memberType) {
      Swal.fire({
        title: "Error",
        text: "Please select a member type",
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    }

    if (!/^\d{10}$/.test(phone)) {
      Swal.fire({
        title: "Error",
        text: "Phone number must be 10 digits",
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    }

    // if (!physicalAddress) {
    //   Swal.fire({
    //     title: "Error",
    //     text: "Please provide a physical address",
    //     icon: "error",
    //     customClass: {
    //       confirmButton: "swal-confirm-button", // Custom class for the confirmation button
    //     },
    //     buttonsStyling: false, // Disable default styling to use custom styles
    //     confirmButtonColor: "#4CAF50", // Background color for the confirmation button
    //   });
    //   return false;
    // }

    if (!helpWith) {
      Swal.fire({
        title: "Error",
        text: 'Please select at least one option for "What do you need help with?"',
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    }

    if (!campaignSource) {
      Swal.fire({
        title: "Error",
        text: "Please provide a campaign source",
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    }

    // if (!registrationType) {
    //   Swal.fire({
    //     title: "Error",
    //     text: "Please provide a Registration Type",
    //     icon: "error",
    //     customClass: {
    //       confirmButton: "swal-confirm-button",
    //     },
    //     buttonsStyling: false,
    //     confirmButtonColor: "#4CAF50",
    //   });
    //   return false;
    // }

    if (signCanvasRef.current.isEmpty()) {
      Swal.fire({
        title: "Error",
        text: "Please Sign",
        icon: "error",
        customClass: {
          confirmButton: "swal-confirm-button", // Custom class for the confirmation button
        },
        buttonsStyling: false, // Disable default styling to use custom styles
        confirmButtonColor: "#4CAF50", // Background color for the confirmation button
      });
      return false;
    }
    return true;
  };

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <AppBar />
        <Start />
        <Card />
        <Toolbar />
        <Box
  sx={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh', 
    zIndex: -1,
    overflow: 'hidden', 
  }}
>
  <Carousel
    showThumbs={false}
    autoPlay
    infiniteLoop
    showStatus={false}
    showArrows={false}
    showIndicators={false}
    interval={5000}
  >
    <div>
      <img src={P1} alt="Slide 1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    </div>
    <div>
      <img src={P2} alt="Slide 2" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    </div>
    <div>
      <img src={P3} alt="Slide 3" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    </div>
  </Carousel>
</Box>

        <Paper
          ref={mainFormRef}
          sx={{

            p: 2,
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            bgcolor: "#FFFFFF",
            width: "100%",
            maxWidth: "1000px", // Adjust maxWidth as needed to fit the form components
          }}
        >
          <Divider
            sx={{ borderBottomWidth: "2px", borderBottomColor: "black" }}
          />

          <FormControl
            sx={{ width: "50%", margin: "auto", marginBottom: "2px" }}
          >
            <FormLabel id="popi_act_label">
              <Typography sx={{ marginTop: "5%" }} gutterBottom>
                DISCLAIMER : Please note, the collection of personal information
                is protected by the POPI Act.
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-labelledby="popi_act_label"
              defaultValue=""
              name="disclaimer"
              onChange={handleChange}
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            </RadioGroup>
          </FormControl>

          <FormControl
            fullWidth
            variant="outlined"
            sx={{
              width: "50%",
              margin: "auto",
              marginBottom: "2%",
              marginTop: "2%",
            }}
          >
            <InputLabel>Province.</InputLabel>
            <Select
              name="province"
              value={formData.province}
              onChange={handleProvinceChange}
              label="Province"
            >
              {Object.keys(provinceOptions).map((province) => (
                <MenuItem key={province} value={province}>
                  {province}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Required</FormHelperText>
          </FormControl>

          <FormControl
            sx={{ width: "50%", margin: "auto", marginBottom: "4px" }}
          >
            <InputLabel id="Event-label">Event Type *</InputLabel>
            <Select
              labelId="Event-label"
              id="Event-required"
              value={formData.eventType}
              name="eventType"
              label="Event Type *"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>- Choose -</em>
              </MenuItem>
              <MenuItem value="Retirement Member Campaign(RMC)">
                Retirement Member Campaign(RMC)
              </MenuItem>
             {/*} <MenuItem value="GEPF Day">GEPF Day</MenuItem>*/}
            </Select>
            <FormHelperText>Required</FormHelperText>
          </FormControl>

          <FormControl
            fullWidth
            variant="outlined"
            sx={{
              width: "50%",
              margin: "auto",
              marginBottom: "2%",
              marginTop: "2%",
            }}
          >
            <InputLabel>Area</InputLabel>
            <Select
              name="area"
              value={formData.area}
              onChange={handleChange}
              label="Area"
              disabled={!formData.province}
            >
              {formData.province &&
                provinceOptions[formData.province].map((area) => (
                  <MenuItem key={area} value={area}>
                    {area}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>Required</FormHelperText>
          </FormControl>

          <FormControl
            sx={{
              width: "50%",
              margin: "auto",
              marginBottom: "2%",
              marginTop: "2%",
            }}
          >
            <InputLabel id="Title-label">Title *</InputLabel>
            <Select
              labelId="Title-label"
              id="Title-required"
              value={formData.title}
              name="title"
              label="Title *"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>- Choose -</em>
              </MenuItem>
              <MenuItem value="Mr">Mr</MenuItem>
              <MenuItem value="Miss">Miss</MenuItem>
              <MenuItem value="Mrs">Mrs</MenuItem>
              <MenuItem value="Ms">Ms</MenuItem>
              <MenuItem value="Dr">Dr</MenuItem>
              <MenuItem value="Prof">Prof.</MenuItem>
            </Select>
            <FormHelperText>Required</FormHelperText>
          </FormControl>

          <TextField
            id="initials"
            label="Initials *"
            variant="outlined"
            sx={{
              width: "50%",
              margin: "auto",
              marginBottom: "2%",
              marginTop: "2%",
            }}
            name="initials"
            value={formData.initials}
            onChange={handleChange}
          />
          <TextField
            id="firstName"
            label="First Name *"
            variant="outlined"
            sx={{
              width: "50%",
              margin: "auto",
              marginBottom: "2%",
              marginTop: "2%",
            }}
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
          <TextField
            id="lastName"
            label="Last Name *"
            variant="outlined"
            sx={{
              width: "50%",
              margin: "auto",
              marginBottom: "2%",
              marginTop: "2%",
            }}
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />

          <FormControl
            sx={{ width: "50%", margin: "auto", marginBottom: "2px" }}
          >
            <FormLabel id="popi_act_label">
              <Typography sx={{ marginTop: "5%" }} gutterBottom>
                Do you consent to providing your ID/Pension Number ? *
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-labelledby="consentID"
              defaultValue=""
              name="consentID"
              onChange={handleChange}
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>

          <TextField
            id="idNumber"
            label="ID Number *"
            variant="outlined"
            sx={{
              width: "50%",
              margin: "auto",
              marginBottom: "2%",
              marginTop: "2%",
            }}
            name="idNumber"
            value={formData.idNumber}
            onChange={handleChange}
          />
          <TextField
            id="pensionNumber"
            label="Pension Number"
            variant="outlined"
            sx={{
              width: "50%",
              margin: "auto",
              marginBottom: "2%",
              marginTop: "2%",
            }}
            name="pensionNumber"
            value={formData.pensionNumber}
            onChange={handleChange}
          />
          {/* <TextField
            id="department"
            label="Department *"
            variant="outlined"
            sx={{
              width: "50%",
              margin: "auto",
              marginBottom: "2%",
              marginTop: "2%",
            }}
            name="department"
            value={formData.department}
            onChange={handleChange}
          /> */}
          <FormControl
            sx={{ width: "50%", margin: "auto", marginBottom: "4px" }}
          >
            <InputLabel id="department-label">Department *</InputLabel>
            <Select
              labelId="department-label"
              id="department-required"
              value={formData.department}
              name="department"
              label="Department *"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>- Choose -</em>
              </MenuItem>
              <MenuItem value="Education">Education</MenuItem>
              <MenuItem value="Health">Health</MenuItem>
              <MenuItem value="SAPS">SAPS</MenuItem>
              <MenuItem value="Public Work">Public Work</MenuItem>
              <MenuItem value="Correctional Services">Correctional Services</MenuItem>
              <MenuItem value="Defence Force">Defence Force</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
            <FormHelperText>Required</FormHelperText>
            {formData.department === "Other" && (
              <TextField
                sx={{ marginTop: "16px" }}
                id="other-text"
                label="Please specify"
                name="otherText"
                value={formData.otherText}
                onChange={handleChange}
                fullWidth
              />
            )}
          </FormControl>

          <FormControl
            sx={{ width: "50%", margin: "auto", marginBottom: "4px",marginTop: "4px" }}
          >
            <InputLabel id="Member-label">Member Type *</InputLabel>
            <Select
              labelId="Member-label"
              id="Member-required"
              value={formData.memberType}
              name="memberType"
              label="Member Type *"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>- Choose -</em>
              </MenuItem>
              <MenuItem value="Active Member">Active Member</MenuItem>
              <MenuItem value="Pensioner">Pensioner</MenuItem>
              <MenuItem value="Beneficiary">Beneficiary</MenuItem>
              <MenuItem value="Spouse">Spouse</MenuItem>
              <MenuItem value="HR Representative">HR Representative</MenuItem>
            </Select>
            <FormHelperText>Required</FormHelperText>
          </FormControl>

          <TextField
            id="phone"
            label="Phone number *"
            variant="outlined"
            sx={{
              width: "50%",
              margin: "auto",
              marginBottom: "6px",
              marginTop: "3%",
            }}
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
          <TextField
            id="email"
            label="Email Address "
            variant="outlined"
            sx={{
              width: "50%",
              margin: "auto",
              marginBottom: "6px",
              marginTop: "2%",
            }}
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            sx={{
              width: "50%",
              margin: "auto",
              marginBottom: "6px",
              marginTop: "2%",
            }}
            id="physicalAddress"
            label="Physical Address"
            multiline
            rows={4}
            name="physicalAddress"
            value={formData.physicalAddress}
            onChange={handleChange}
          />

          <FormControl
            sx={{
              width: "50%",
              margin: "auto",
              marginBottom: "4px",
              marginTop: "2%",
            }}
          >
            <InputLabel
              id="Member-label"
              sx={{
                backgroundColor: "white",
                padding: "0 4px",
                transform: "translate(14px, -6px) scale(0.75)",
                zIndex: 1,
              }}
            >
              What do you need help with? *
            </InputLabel>
            <Select
              labelId="Member-label"
              id="Member-required"
              value={formData.helpWith}
              name="helpWith"
              label="Member Type *"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>- Choose -</em>
              </MenuItem>
              <MenuItem value="Overview of your Benefits">
                Overview of Benefits
              </MenuItem>
              <MenuItem value="2-Pot">2-Pot System</MenuItem>
              <MenuItem value="Divorce">Divorce</MenuItem>
              <MenuItem value="Resignation">Resignation</MenuItem>
              <MenuItem value="Retirement">Retirement</MenuItem>
              <MenuItem value="Spounse Pension">Spouse Pension</MenuItem>
              <MenuItem value="Childs Pension">Childs Pension</MenuItem>
              <MenuItem value="Funeral Benefits">Funeral Benefits</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
            <FormHelperText>Required</FormHelperText>
            {formData.helpWith === "Other" && (
              <TextField
                sx={{ marginTop: "16px" }}
                id="other-text"
                label="Please specify"
                name="otherText"
                value={formData.otherText}
                onChange={handleChange}
                fullWidth
              />
            )}
          </FormControl>

          {/* <FormControl
            sx={{ width: "50%", margin: "auto", marginBottom: "2px" }}
          >
            <FormLabel id="popi_act_label">
              <Typography sx={{ marginTop: "5%" }} gutterBottom>
                What do you need help with? *
              </Typography>
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.helpWith.includes(
                      "Overview of your Benefits"
                    )}
                  />
                }
                value="Overview of your Benefits"
                label="Overview of your Benefits"
                onChange={handleCheckboxChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.helpWith.includes("Resignation")}
                  />
                }
                value="Resignation"
                label="Resignation"
                onChange={handleCheckboxChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.helpWith.includes("Normal Retirement")}
                  />
                }
                value="Normal Retirement"
                label="Normal Retirement"
                onChange={handleCheckboxChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.helpWith.includes("Spounse Pension")}
                  />
                }
                value="Spounse Pension"
                label="Spounse Pension"
                onChange={handleCheckboxChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.helpWith.includes("Childs Pension")}
                  />
                }
                value="Childs Pension"
                label="Child's Pension"
                onChange={handleCheckboxChange}
              />
              <FormControlLabel
                control={
                  <Checkbox checked={formData.helpWith.includes("Other")} />
                }
                value="Other"
                label="Other"
                onChange={handleCheckboxChange}
              />
            </FormGroup>
          </FormControl> */}

          <FormControl
            sx={{
              width: "50%",
              margin: "auto",
              marginBottom: "2%",
              marginTop: "2%",
            }}
          >
            <InputLabel
              id="campaign-label"
              sx={{
                backgroundColor: "white",
                padding: "0 4px",
                transform: "translate(14px, -6px) scale(0.75)",
                zIndex: 1,
              }}
            >
              How did you hear about the campaign? *
            </InputLabel>
            <Select
              labelId="campaign-label"
              id="campaign-required"
              value={formData.campaignSource}
              name="campaignSource"
              label="Campaign Source *"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>- Choose -</em>
              </MenuItem>
              <MenuItem value="Social Media">Social Media</MenuItem>
              <MenuItem value="SMS">SMS</MenuItem>
              <MenuItem value="Friend/colleague">Friend/Colleague</MenuItem>
              <MenuItem value="Radio">Radio</MenuItem>
              <MenuItem value="HR/Department">HR/Department</MenuItem>
              <MenuItem value="GEPF website">GEPF Website</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
            <FormHelperText>Required</FormHelperText>
          </FormControl>

          <FormControl
            sx={{
              width: "50%",
              margin: "auto",
              marginBottom: "2%",
              marginTop: "2%",
            }}
          >
            <InputLabel id="registration-label">Registration Type *</InputLabel>
            <Select
              labelId="registration-label"
              id="registration-required"
              value={registrationType}
              name="registrationType"
              label="Registration Type *"
              onChange={handleRegistrationTypeChange}
            >
              <MenuItem value="">
                <em>- Choose -</em>
              </MenuItem>
              <MenuItem value="Pre-Registration">Pre-Registration</MenuItem>
              {/*<MenuItem value="On-site Registration">
                On-site Registration
              </MenuItem> */}
            </Select>
            <FormHelperText>Required</FormHelperText>
          </FormControl>

          <Typography
            sx={{ marginTop: "5%" }}
            gutterBottom
            variant="h5"
            component="h1"
          >
            E-signature
          </Typography>
          {/* <Typography sx={{ marginTop: '5%' }} gutterBottom variant="h5" component="h1">
                        E-signature Pre-signature
                    </Typography> */}

          <div style={{ position: "relative", marginTop: "1rem" }}>
            <div
              style={{
                border: "solid #93AB4F 2px",
                width: "100%",
                height: 200,
                position: "relative",
              }}
            >
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: 660,
                  height: 200,
                  className: "sigCanvas",
                }}
                ref={signCanvasRef}
              />
              <Button
                size="small"
                onClick={() => signCanvasRef.current.clear()} // Clear the signature when clicked
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  color: "grey",
                }}
                sx={{ "&:hover": { color: "#DF6E46" } }}
              >
                CLEAR
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
                width: "100%",
              }}
            >
              <Link style={{ flex: 1, textAlign: "right" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#93AB4F",
                    color: "#FFFFFF",
                    width: "40%",
                    marginLeft: "5px",
                    "&:hover": { backgroundColor: "#DF6E46" },
                  }}
                  endIcon={<SendIcon />}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <CircularProgress
                        size={25}
                        sx={{ marginRight: "8px", color: "#93AB4F" }}
                      />
                      Loading...
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Link>
            </div>
            <IconButton
              sx={{
                right: "20px",
                bottom: "20px",
                position: "fixed",
                backgroundColor: "#93AB4F",
                "&:hover": { backgroundColor: "#DF6E46" },
              }}
              aria-label="scroll to top"
              onClick={scrollToTop}
            >
              <KeyboardDoubleArrowUpOutlinedIcon />
            </IconButton>
          </div>
        </Paper>
      </React.Fragment>
    </ThemeProvider>
  );
}

export default Home;